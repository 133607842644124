import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box, Typography, Button, List, Modal, ListItem, ListItemText, Link, CircularProgress, TextField, Chip, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem, Card, CardContent,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as ImgIcon } from './src/ic-img.svg'; // Import the SVG icon
import { ReactComponent as DocIcon } from './src/ic-document.svg'; // Import the SVG icon
import PdfIcon from './src/ic-pdf.svg'; // actualizează calea la iconul PDF
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AwbAccordion from './CreareAWB'; // Importăm noul component
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the check icon
import ActiveRequests from './ActiveRequests'; // importă componenta
import AnalysisIcon from '@mui/icons-material/Analytics'; // Importați icon-ul pentru analiză
import Page18 from './Page18'; // Importă noua componentă
import AutentificareStep from './autentificare_step'; // Importăm noua componentă
import CreareContractForm from './CreareContractForm'; // Import contract form
import ActeConfidentiale from './ActeConfidentiale';
import VendorInfo from './VendorInfo';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const TaskEdit = ({ issueId, style, tab, onClose, reloadTasks }) => {
    const [task, setTask] = useState({});
    const [status, setStatus] = useState('');
    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [uniqueId, setUniqueId] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [error, setError] = useState('');
    const [isEditingSummary, setIsEditingSummary] = useState(false);
    const [newSummary, setNewSummary] = useState('');
    const [imageSearchResults, setImageSearchResults] = useState([]);
    const [firstImageAttachment, setFirstImageAttachment] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [tags, setTags] = useState([]);
    const [price, setPrice] = useState('');
    const [expandedHistory, setExpandedHistory] = useState(false);
    const [expandedSearch, setExpandedSearch] = useState(false);
    const [transitions, setTransitions] = useState([]);
    const [selectedTransition, setSelectedTransition] = useState('');
    const [customFieldOptions, setCustomFieldOptions] = useState({});
    const [iban, setIban] = useState(''); // Starea pentru IBAN
    const [bankName, setBankName] = useState(''); // Starea pentru numele băncii
    const [vendorData, setVendorData] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null); // Declara stare pentru selectedAddress
    const [alertMessage, setAlertMessage] = useState(''); // Mesajul alertei
    const [alertType, setAlertType] = useState(''); // Tipul alertei ('success' sau 'error')
    const [showAlert, setShowAlert] = useState(false); // Controlează afișarea alertei

    const [customFieldValues, setCustomFieldValues] = useState({
        customfield_10066: '',
        customfield_10077: '',
        customfield_10072: '',
        customfield_10073: '',
        customfield_10080: '',
        customfield_10183: '',
        customfield_10173: '',  // New field for Categorie
        customfield_10078: '',  // New field for Expira Contract
        customfield_10165: '',  // New field for Achizitor
        customfield_10185: '',  // New field for Contactat
        customfield_10067: '',  // New field for PersonIcon
        customfield_10069: '',  // New field for EmailIcon
        customfield_10068: '',  // New field for PhoneIcon
        customfield_10186: '',  // New field for Nota interna
        customfield_10188: '',  // New field for QCRespins
        customfield_10181: ''
    });
    const [history, setHistory] = useState([]);
    const [tipContract, setTipContract] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [selectedReason, setSelectedReason] = useState('');
    const [details, setDetails] = useState('');
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [isStatusUpdated, setIsStatusUpdated] = useState(false);
    const [warnings, setWarnings] = useState({});
    const [scheduledAwb, setScheduledAwb] = useState(null);
    const [showAnalysis, setShowAnalysis] = useState(false); // Stare pentru a controla vizibilitatea analizei
    const [manualAvgPrice, setManualAvgPrice] = useState(''); // Stare pentru Prețul de Vânzare
    const [contractData, setContractData] = useState({
        nume: '',
        prenume: '',
        telefon: '',
        email: '',
        adresa: '',
        serieCI: '',
        nrCI: '',
        numarContract: '',
        tipContract: '',
        um: '1 BUC', // Set default value here
        dataContract: '',
        panaCand: '',
        pretCuvenit: '',
        model: '',
        categoria: ''
    });

    const salReasons = [
        '1. Stare generala uzata: Produsul prezinta semne evidente de uzura, cum ar fi zgarieturi, pete sau decolorari, care nu respecta standardele noastre de calitate.',
        '2. Defecte structurale: Produsul are defecte structurale, cum ar fi cusaturi desfacute, fermoare defecte sau parti lipsa, care afecteaza functionalitatea si estetica.',
        '3. Piese neoriginale sau inlocuite: Am constatat ca anumite componente ale produsului nu sunt originale sau au fost inlocuite, ceea ce afecteaza autenticitatea si valoarea acestuia.',
        '4. Semne de reparatii neautorizate: Produsul prezinta semne de reparatii sau modificari care nu au fost realizate de un specialist autorizat, ceea ce reduce valoarea si integritatea sa.',
        '5. Lipsa documentatiei necesare: Produsul nu este insotit de documentatia necesara, cum ar fi certificate de autenticitate, facturi sau alte acte, ceea ce ne impiedica sa confirmam provenienta si autenticitatea.',
        '6. Miros persistent: Produsul emana un miros persistent care nu poate fi eliminat si care nu este compatibil cu standardele noastre de calitate.',
        '7. Culoare modificata: Culoarea produsului a suferit modificari semnificative, fie din cauza expunerii la soare, fie din alte cauze, ceea ce il face nepotrivit pentru vanzare.',
        '8. Uzura interioara excesiva: Interiorul produsului prezinta semne de uzura excesiva, cum ar fi captuseala deteriorata, pete sau alte defecte, care afecteaza atractivitatea acestuia.',
        '9. Materiale deteriorate: Materialele din care este confectionat produsul, cum ar fi pielea sau metalul, prezinta semne de deteriorare, cum ar fi crapaturi, rugina sau decolorarea.',
        '10. Nerespectarea cerintelor de brand: Produsul nu respecta standardele de calitate si design specifice brandului, ceea ce ne impiedica sa il acceptam pentru vanzare in consignatia noastra.',
        '11. Pretul propus nu a fost acceptat: Dupa evaluarea produsului, pretul nou propus nu a fost acceptat de catre dumneavoastra, ceea ce ne impiedica sa continuam procesul de consignatie.',
        '12. Lipsa accesoriilor sau a componentelor mentionate: Produsul nu a fost insotit de toate accesoriile sau componentele mentionate in cererea initiala, ceea ce ne impiedica sa il acceptam pentru consignatie.',
        '13. Produsul nu poate fi acceptat, deoarece ați refuzat opțiunea de consignare, iar achiziția directă nu se încadrează în politica noastră actuală.',
        '14. Brand: Articolul nu este eligibil deoarece brandul nu se încadrează în portofoliul nostru de produse acceptate.',
        '15. Preț: Articolul nu este eligibil deoarece prețul propus nu corespunde cu evaluarea noastră sau cu cerințele actuale ale pieței de lux.',
        '16. Stare: Articolul nu este eligibil deoarece starea acestuia nu îndeplinește standardele de calitate necesare pentru acceptarea în consignatie.'
    ];

    const handleOpenPopup = () => setShowPopup(true);

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedReason('');
        setDetails('');
    };

    const handleReasonChange = (reason) => {
        setSelectedReason(reason);
        setDetails(reason); // Copiază motivul în câmpul Detalii suplimentare
    };

    const handleSaveRefuse = async () => {
        if (!details.trim()) {
            alert('Vă rugăm să completați detaliile refuzului.');
            return;
        }

        try {
            // Salvăm detaliile în JIRA
            await axios.put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, {
                customfield_10193: details,
            });

            // Apelăm logica existentă pentru "Refuz Total"
            handleTotalRefuse();
            handleClosePopup();
        } catch (error) {
            console.error('There was an error saving the refuse reason:', error);
        }
    };

useEffect(() => {
    const fetchData = async () => {
        try {
            // Fetch task data
            const taskResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
            const taskData = taskResponse.data;

            if (taskData.error) {
                setError(taskData.error);
            } else {

                // Afișăm descrierea completă în consolă pentru debugging
                console.log("Task Description:", taskData.description);

                // Extragem Conditie si Detin din descriere
                const extractTagsAndDetails = (description) => {
                    // Extragem condiția folosind un regex simplificat
                    // Aici luăm tot textul de pe aceeași linie după "Conditie"
                    // Normalizează spațiile non-breaking
                    const normalizedDescription = description.replace(/\u00a0/g, ' ');

                    // Regex care extrage orice text între "Conditie" și "Model" fără a include "Model"
                    // (?=Model) este un lookahead care asigură că ne oprim înainte de "Model"
                    const conditionMatch = normalizedDescription.match(/Conditie\s+([\s\S]*?)(?=Model)/i);

                    let condition = 'N/A';

                    if (conditionMatch) {
                        // Impărțim textul capturat în cuvinte și luăm doar primul
                        const words = conditionMatch[1].trim().split(/\s+/);
                        condition = words[0] || 'N/A';
                    }

                    // Extragem detaliile "Dețin" până la "Prețul cerut"
                    const detinMatch = description.match(/Dețin\s*([\s\S]*?)Prețul cerut/i);
                    let detin = detinMatch 
                        ? detinMatch[1]
                            .split('*')
                            .map(item => item.trim())
                            .filter(item => item) 
                        : [];
                    detin = detin.length > 0 ? detin : ['Nimic'];

                    return { condition, detin };
                };

                const { condition, detin } = extractTagsAndDetails(taskData.description);

                // Setăm detaliile extrase în state
                setCustomFieldValues(prev => ({ ...prev, condition }));
                setTags(detin);

                console.log('Condiție:', condition);
                console.log('Dețin:', detin);

                // Fetch custom field options first
                const customFields = [
                    
                    "customfield_10161",
                    "customfield_10160",
                    "customfield_10080",
                    "customfield_10183",
                    "customfield_10173",
                    "customfield_10165",
                    "customfield_10185",
                    "customfield_10099", // Added customfield_10099
                ];
                const options = {};

                // Fetch options for each custom field
                for (const field of customFields) {
                    const customFieldResponse = await axios.get(
                        `https://server-9p4hx.kinsta.app/api/tasks/jira-custom-field-options/${field}`
                    );
                    options[field] = customFieldResponse.data;
                }

                setCustomFieldOptions(options);

                // Helper function to get label from value
                const getLabelForValue = (fieldId, value) => {
                    const fieldOptions = options[fieldId];
                    if (fieldOptions) {
                        const option = fieldOptions.find((opt) => opt.value === value);
                        return option ? option.label : value;
                    }
                    return value;
                };

                // Helper function to get labels for multiple values (e.g., checkboxes)
                const getLabelsForValues = (fieldId, values) => {
                    const fieldOptions = options[fieldId];
                    if (fieldOptions && Array.isArray(values)) {
                        const labels = values.map((value) => {
                            const option = fieldOptions.find((opt) => opt.value === value.id);
                            return option ? option.label : value.id;
                        });
                        return labels;
                    }
                    return [];
                };

                // Set task details
                setTask(taskData);
                setStatus(taskData.status);
                setSummary(taskData.summary);
                setNewSummary(taskData.summary);
                setDescription(taskData.description);
                setAttachments(taskData.attachments);

                // Set first image attachment
                const firstImage = taskData.attachments.find((att) => {
                    const fileExtension = att.filename.split('.').pop().toLowerCase();
                    return ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
                });
                setFirstImageAttachment(firstImage);

                // Extract tags and price from description
                extractTagsAndPrice(taskData.description);

                // MODIFICARE: Stocăm mai întâi valoarea din JIRA sau din uniqueId
                let nrJurnalValue = taskData.customfield_10066 || taskData.uniqueId || '';

                // Dacă nu avem valoare din JIRA, încercăm să extragem din descriere
                if (!taskData.customfield_10066 || taskData.customfield_10066.trim() === '') {
                    const nrJurnalFromDescription = extractNrJurnal(taskData.description);
                    if (nrJurnalFromDescription) {
                        nrJurnalValue = nrJurnalFromDescription;
                    }
                }

                // Set custom field values cu nrJurnalValue final
                setCustomFieldValues(prev => ({
                    ...prev,
                    customfield_10066: nrJurnalValue,
                    customfield_10077: taskData.customfield_10077 || '',
                    customfield_10072: taskData.customfield_10072 || '',
                    customfield_10073: taskData.customfield_10073 || '',
                    customfield_10080: taskData.customfield_10080 || '',
                    customfield_10183: taskData.customfield_10183 || '',
                    customfield_10173: taskData.customfield_10173 || '',
                    customfield_10078: taskData.customfield_10078 || '',
                    customfield_10165: taskData.customfield_10165 || '',
                    customfield_10185: taskData.customfield_10185 || '',
                    customfield_10067: taskData.customfield_10067 || '',
                    customfield_10069: taskData.customfield_10069 || '',
                    customfield_10068: taskData.customfield_10068 || '',
                    customfield_10186: taskData.customfield_10186 || '',
                    customfield_10188: taskData.customfield_10188 || '',
                    customfield_10195: taskData.customfield_10195 || '',
                    customfield_10194: taskData.customfield_10194 || '',
                    customfield_10181: taskData.customfield_10181 || '', // Preț Final
                }));

                // Set contact information from taskData
                setEmail(taskData.email || '');
                setName(taskData.name || '');
                setPhone(taskData.phone || '');
                setUniqueId(taskData.uniqueId || '');

                console.log('Name:', taskData.name);
                console.log('Phone:', taskData.phone);
                console.log('Email:', taskData.email);

                // Extract first name (prenume) and last name (nume) from full name
                const fullName = taskData.name || '';
                const nameParts = fullName.trim().split(' ');
                const nume = nameParts.slice(0, -1).join(' ') || '';
                const prenume = nameParts.slice(-1).join(' ') || '';

                // Get labels for customfield_10099
                const checkboxLabels = getLabelsForValues('customfield_10099', taskData.customfield_10099);
                const checkboxLabelsString = checkboxLabels.join(', ');

                // Map labels instead of IDs to contractData
                setContractData((prevContractData) => ({
                    ...prevContractData,
                    numarContract: nrJurnalValue, // Folosim valoarea finală pentru Nr Contract
                    tipContract: getLabelForValue('customfield_10183', taskData.customfield_10183) || '',
                    telefon: taskData.phone || '',
                    email: taskData.email || '',
                    nume: nume,
                    prenume: prenume,
                    dataContract: taskData.customfield_10077
                        ? taskData.customfield_10077.substring(0, 10)
                        : '', // Data de Intrare
                    panaCand: taskData.customfield_10078
                        ? taskData.customfield_10078.substring(0, 10)
                        : '', // Expira Contract
                    pretCuvenit: taskData.customfield_10072 || '', // Preț de Intrare (RON)
                    model: taskData.customfield_10107 || '', // Model
                    categoria: getLabelForValue('customfield_10173', taskData.customfield_10173) || '',
                    brand: taskData.customfield_10070 || '', // Mapped directly
                    banca: taskData.customfield_10175 || '', // Mapped directly
                    contCurent: taskData.customfield_10176 || '', // Mapped directly
                    numeTitularCont: nume, prenume,
                    checkboxOptions: checkboxLabelsString || '',
                }));
            }

            // Fetch transitions
            const transitionsResponse = await axios.get(
                `https://server-9p4hx.kinsta.app/api/tasks/jira-transitions/${issueId}`
            );
            setTransitions(transitionsResponse.data);
        } catch (error) {
            console.error('There was an error fetching the task data!', error);
            setError('There was an error fetching the task data!');
        }
    };

    fetchData();
}, [issueId]);



    const [awbData, setAwbData] = useState({
        street: '',
        number: '',
        postalCode: '',
        city: '',
        county: '',
        country: 'Romania'
    });

    const handleAddressSelect = (address) => {
        setSelectedAddress(address); // Actualizează selectedAddress
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const taskResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
                const taskData = taskResponse.data;

                // Debugging pentru customfield_10077
                console.log('Valoare customfield_10077 preluată din API:', taskData.customfield_10077);

                setCustomFieldValues((prevValues) => ({
                    ...prevValues,
                    customfield_10066: taskData.customfield_10066 || 'N/A', // Nr Contract
                    customfield_10181: taskData.customfield_10181 || '0',   // Preț Final
                    customfield_10077: taskData.customfield_10077 || '',    // Data Intrare
                }));
            } catch (error) {
                console.error('Eroare la preluarea datelor task-ului:', error);
            }
        };

        fetchData();
    }, [issueId]);



    useEffect(() => {
        const fetchHistory = async () => {
            if (email) {
                setIsLoadingHistory(true);
                try {
                    const historyResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/tasks-by-email?email=${email}`);
                    setHistory(historyResponse.data);
                } catch (error) {
                    console.error('There was an error fetching the task history!', error);
                } finally {
                    setIsLoadingHistory(false);
                }
            }
        };

        fetchHistory();
    }, [email]);

    useEffect(() => {
        const fetchScheduledAwb = async () => {
            try {
                const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/scheduled-awb/${issueId}`);
                if (response.data) {
                    setScheduledAwb(response.data);
                }
            } catch (error) {
                console.error('There was an error fetching the scheduled AWB!', error);
            }
        };

        if (tab === 'acceptat') {
            fetchScheduledAwb();
        }
    }, [issueId, tab]);



    // Resetarea ferestrei atunci când un alt task este selectat
    useEffect(() => {
            setTask({});
            setStatus('');
            setSummary('');
            setDescription('');
            setEmail('');
            setName('');
            setPhone('');
            setUniqueId('');
            setAttachments([]);
            setError('');
            setIsEditingSummary(false);
            setNewSummary('');
            setImageSearchResults([]);
            setFirstImageAttachment(null);
            setIsSearching(false);
            setTags([]);
            setPrice('');
            setExpandedHistory(false);
            setExpandedSearch(false);
            setTransitions([]);
            setSelectedTransition('');
            setCustomFieldOptions({});
            setCustomFieldValues({
                customfield_10066: '',
                customfield_10077: '',
                customfield_10072: '',
                customfield_10073: '',
                customfield_10080: '',
                customfield_10183: '',
                customfield_10173: '',
                customfield_10078: '',
                customfield_10165: '',
                customfield_10185: '',
                customfield_10067: '',
                customfield_10069: '',
                customfield_10068: '',
                customfield_10186: '',
                customfield_10188: '',
                customfield_10181: ''
            });
            setHistory([]);
            setIsLoadingHistory(false);
            setIsStatusUpdated(false);
            setScheduledAwb(null);

            // Reset contractData
            setContractData({
                nume: '',
                prenume: '',
                telefon: '',
                email: '',
                adresa: '',
                serieCI: '',
                nrCI: '',
                numarContract: '',
                tipContract: ''
            });
    }, [issueId]);

    useEffect(() => {
        if (showAnalysis) {
            document.body.style.overflow = 'hidden'; // Blochează scroll-ul pe fundal
        } else {
            document.body.style.overflow = 'auto'; // Reactivă scroll-ul pe fundal
        }

        // Curăță stilurile când componenta este demontată
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showAnalysis]);


    useEffect(() => {
        const fetchTaskDetails = async () => {
            try {
                const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
                setStatus(response.data.status);
                const transitionsResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-transitions/${issueId}`);
                setTransitions(transitionsResponse.data);
            } catch (error) {
                console.error('There was an error fetching the task details!', error);
            }
        };

        fetchTaskDetails();
    }, [issueId]);

    // Acesta este useEffect-ul nou
    useEffect(() => {
        const fetchTaskDetails = async () => {
        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
            // Set task details including customfield_10188
            setCustomFieldValues(prevValues => ({
            ...prevValues,
            customfield_10188: response.data.customfield_10188 || '', // Setăm customfield_10188
            }));
        } catch (error) {
            console.error('There was an error fetching the task details!', error);
        }
        };

        fetchTaskDetails();
    }, [issueId]);

    // Fetch task data
    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
                const taskData = response.data;
                
                if (taskData.email && taskData.email.trim()) {
                    setEmail(taskData.email.trim());
                } else {
                    console.warn('No valid email found in task data.');
                }
            } catch (error) {
                console.error('Error fetching task data:', error);
            }
        };

        fetchTaskData();
    }, [issueId]);

    const handleTransition = async (newStatus) => {
        const transition = transitions.find(t => t.name === newStatus);
        if (!transition) {
            console.error(`No transition found for status "${newStatus}"`);
            return;
        }
        const transitionId = transition.id;

        try {
            await axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-transition`, {
                issueId,
                transitionId
            });

            setStatus(newStatus);
            showStatusUpdatedMessage();
        } catch (error) {
            console.error(`There was an error transitioning to "${newStatus}"!`, error);
        }
    };

    const handleOnline = () => {
        handleTransition('ONLINE');
    };

    const handleRespins = () => {
        handleTransition('RESPINS');
    };

    const handleRefreshHistory = () => {
        setIsLoadingHistory(true);
        axios.get(`https://server-9p4hx.kinsta.app/api/tasks/tasks-by-email?email=${email}`)
            .then(response => {
                setHistory(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the task history!', error);
            })
            .finally(() => {
                setIsLoadingHistory(false);
            });
    };

    const extractTagsAndPrice = (description) => {
        const tagsMatch = description.match(/Dețin\s*([\s\S]*?)Prețul cerut\s*([\d\s.,]+)/i);
        if (tagsMatch) {
            const tagsString = tagsMatch[1];
            const mappedTags = [];

            const possibleTags = ['Cutie', 'Dust bag', 'Factura / Bon', 'Factură', 'Certificat'];
            possibleTags.forEach(tag => {
                const regex = new RegExp(`\\*?\\s*${tag}`, 'i');
                if (regex.test(tagsString)) {
                    mappedTags.push(tag.replace(' / Bon', '')); // normalize tag names
                }
            });

            if (mappedTags.length === 0) mappedTags.push('Nimic');

                // Extract and format the price
                let price = tagsMatch[2].trim();
                price = price.replace(/\s/g, '').replace('.', '').replace(',', ''); // Remove spaces, dots, and commas

                //setTags(mappedTags);
                setPrice(price);
            } else {
                setTags(['Nimic']);
                setPrice(''); // Reset price if not found
            }
    };


    const handleDeleteTag = (tagToDelete) => () => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const handleSave = () => {
        // Construim payload-ul doar cu valorile completate
        const fieldsToUpdate = {};

        if (newSummary.trim()) {
            fieldsToUpdate.summary = newSummary; // Include summary doar dacă este completat
        }

        if (customFieldValues.customfield_10181) {
            fieldsToUpdate.customfield_10181 = customFieldValues.customfield_10181; // Preț Act
        }

        if (customFieldValues.customfield_10078) {
            fieldsToUpdate.customfield_10078 = customFieldValues.customfield_10078; // Expiră
        }

        if (customFieldValues.customfield_10072) {
            fieldsToUpdate.customfield_10072 = parseFloat(customFieldValues.customfield_10072); // Preț Intrare
        }

        if (customFieldValues.customfield_10073) {
            fieldsToUpdate.customfield_10073 = parseFloat(customFieldValues.customfield_10073); // Preț Vânzare
        }

        if (name.trim()) {
            fieldsToUpdate.customfield_10067 = name; // Mapare Nume
        }

        if (email.trim()) {
            fieldsToUpdate.customfield_10069 = email; // Mapare Email
        }

        if (phone.trim()) {
            fieldsToUpdate.customfield_10068 = phone; // Mapare Telefon
        }

        if (customFieldValues.customfield_10186) {
            fieldsToUpdate.customfield_10186 = customFieldValues.customfield_10186; // Notă internă
        }

        if (customFieldValues.customfield_10183) {
            fieldsToUpdate.customfield_10183 = customFieldValues.customfield_10183; // Luxura Policy
        }

        if (customFieldValues.customfield_10080) {
            fieldsToUpdate.customfield_10080 = customFieldValues.customfield_10080; // Tip Achiziție
        }

        if (customFieldValues.customfield_10077) {
            fieldsToUpdate.customfield_10077 = customFieldValues.customfield_10077; // Data Intrare
        }

        if (customFieldValues.customfield_10173) {
            fieldsToUpdate.customfield_10173 = customFieldValues.customfield_10173; // Categorie
        }

        if (customFieldValues.customfield_10165) {
            fieldsToUpdate.customfield_10165 = customFieldValues.customfield_10165; // Achizitor
        }

        if (customFieldValues.customfield_10185) {
            fieldsToUpdate.customfield_10185 = customFieldValues.customfield_10185; // Contactat
        }

        if (customFieldValues.customfield_10066) {
            fieldsToUpdate.customfield_10066 = customFieldValues.customfield_10066; // Nr Jurnal
        }

        // Efectuăm apelul API doar dacă există câmpuri de actualizat
        if (Object.keys(fieldsToUpdate).length > 0) {
            axios
                .put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, fieldsToUpdate)
                .then(() => {
                    setSummary(newSummary);
                    alert('Modificările au fost salvate cu succes!');
                })
                .catch((error) => {
                    console.error('There was an error updating the task!', error);
                    setError('There was an error updating the task!');
                });
        } else {
            alert('Nu există câmpuri completate pentru a fi salvate.');
        }
    };

    const handleSaveSummary = () => {
        axios.put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, { summary: newSummary })
            .then(() => {
                setSummary(newSummary);
                setIsEditingSummary(false);
            })
            .catch(error => {
                console.error('There was an error updating the summary!', error);
                setError('There was an error updating the summary!');
            });
    };

    const handleSaveName = () => {
        axios.put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, { customfield_10067: name })
            .then(() => {
                // Update name in state
            })
            .catch(error => {
                console.error('There was an error updating the name!', error);
                setError('There was an error updating the name!');
            });
    };

    const handleSavePhone = () => {
        axios.put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, { customfield_10068: phone })
            .then(() => {
                // Update phone in state
            })
            .catch(error => {
                console.error('There was an error updating the phone!', error);
                setError('There was an error updating the phone!');
            });
    };

    const handleSaveEmail = () => {
        axios.put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, { customfield_10069: email })
            .then(() => {
                // Update email in state
            })
            .catch(error => {
                console.error('There was an error updating the email!', error);
                setError('There was an error updating the email!');
            });
    };

    const handleAttachmentClick = (attachmentUrl) => {
        window.open(attachmentUrl, '_blank');
    };

    const handleSummaryClick = () => {
        setIsEditingSummary(true);
    };

    const handleSummaryChange = (event) => {
        setNewSummary(event.target.value);
    };

    const handleSummaryBlur = () => {
        handleSaveSummary();
    };

    const renderAttachmentPreview = (attachment) => {
        const fileExtension = attachment.filename.split('.').pop().toLowerCase();
        const commonImageTypes = ['jpg', 'jpeg', 'png', 'gif'];
        const isImage = commonImageTypes.includes(fileExtension);
        const isPDF = fileExtension === 'pdf';

        if (isImage) {
            return (
                <img
                    src={`https://server-9p4hx.kinsta.app/api/tasks/attachments/${attachment.id}`}
                    alt={attachment.filename}
                    style={{ width: '64px', height: '64px', borderRadius: '8px', marginRight: '10px', cursor: 'pointer' }}
                    onClick={() => handleAttachmentClick(`https://server-9p4hx.kinsta.app/api/tasks/attachments/${attachment.id}`)}
                    draggable
                    onDragStart={(e) => handleDragStart(e, attachment)}
                />
            );
        } else if (isPDF) {
            return (
                <img
                    src={PdfIcon}
                    alt="PDF icon"
                    style={{ width: '64px', height: '64px', marginRight: '10px', cursor: 'pointer' }}
                    onClick={() => handleAttachmentClick(`https://server-9p4hx.kinsta.app/api/tasks/attachments/${attachment.id}`)}
                />
            );
        }
        return null;
    };

    const handleDragStart = (e, attachment) => {
        e.dataTransfer.setData('attachment', JSON.stringify(attachment));
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const attachment = JSON.parse(e.dataTransfer.getData('attachment'));
        const currentFirstImage = firstImageAttachment;

        setFirstImageAttachment(attachment);
        setAttachments(attachments.map(att => {
            if (att.id === attachment.id) {
                return currentFirstImage;
            }
            return att;
        }));
    };

    const uploadImage = (imageFile) => {
        const formData = new FormData();
        formData.append('image', imageFile);

        return axios.post(`https://server-9p4hx.kinsta.app/api/tasks/upload-image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    const handleImageSearch = () => {
        setIsSearching(true); // Set searching state to true when starting the search

        if (firstImageAttachment) {
            const imageUrl = `https://server-9p4hx.kinsta.app/api/tasks/attachments/${firstImageAttachment.id}`;
            axios.get(imageUrl, { responseType: 'blob' })
                .then(response => {
                    const imageFile = new File([response.data], firstImageAttachment.filename);
                    return uploadImage(imageFile);
                })
                .then(uploadResponse => {
                    const imageUrl = uploadResponse.data.imageUrl;
                    console.log(`Image URL uploaded: ${imageUrl}`);
                    return axios.post(`https://server-9p4hx.kinsta.app/api/tasks/search-image`, { imageUrl });
                })
                .then(searchResponse => {
                    setImageSearchResults(searchResponse.data.results);
                    setExpandedSearch(true); // Expand the accordion when search results are available
                })
                .catch(error => {
                    console.error('There was an error searching the image!', error);
                })
                .finally(() => {
                    setIsSearching(false); // Set searching state to false when the search is complete
                });
        } else {
            console.error('No image attachment found!');
            setIsSearching(false);
        }
    };

    const handleAccept = async () => {
        // Verificăm dacă suntem în tab-ul "CREATED"
        if (tab === 'created') {
            const transition = transitions.find(t => t.name === 'PROSPECT');
            if (!transition) {
                setError('No transition found for status "PROSPECT"');
                return;
            }
            const transitionId = transition.id;

            try {
                // Tranziționăm taskul la statusul "PROSPECT"
                await axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-transition`, {
                    issueId,
                    transitionId
                });

                setStatus("PROSPECT"); // Setăm noul status la "PROSPECT"
                showStatusUpdatedMessage();
            } catch (error) {
                console.error('There was an error transitioning to "PROSPECT"!', error);
                setError('There was an error transitioning to "PROSPECT"!');
            }
        } else if (tab === 'prospect') {
            // Tranziționăm la "ACCEPTAT" dacă suntem deja în "PROSPECT"
            const transition = transitions.find(t => t.name === 'ACCEPTAT');
            if (!transition) {
                setError('No transition found for status "ACCEPTAT"');
                return;
            }
            const transitionId = transition.id;

            try {
                // Tranziționăm taskul la statusul "ACCEPTAT"
                await axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-transition`, {
                    issueId,
                    transitionId
                });

                setStatus("ACCEPTAT"); // Setăm noul status la "ACCEPTAT"
                
                try {
                    // Trimiterea SMS-ului doar dacă ne aflăm în tab-ul "PROSPECT"
                    await axios.post('https://server-9p4hx.kinsta.app/api/tasks/send-acceptance-sms', {
                        contactName: name,
                        contactPhone: phone,
                        summary,
                        entryPrice: customFieldValues.customfield_10072 // Prețul de intrare
                    });
                } catch (smsError) {
                    console.error('There was an error sending the SMS!', smsError);
                    // Ignorăm eroarea de la SMS și continuăm
                }

                showStatusUpdatedMessage();
            } catch (error) {
                console.error('There was an error performing the transition!', error);
                setError('There was an error performing the transition!');
            }
        }
    };

    // Adăugăm funcția handleTransit
    const handleTransit = () => {
        const transition = transitions.find(t => t.name === 'TRANZIT');
        if (!transition) {
            setError('No transition found for status "TRANZIT"');
            return;
        }
        const transitionId = transition.id;
        axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-transition`, {
            issueId,
            transitionId
        })
            .then(() => {
                setStatus("TRANZIT");
                showStatusUpdatedMessage();
            })
            .catch(error => {
                console.error('There was an error performing the transition!', error);
                setError('There was an error performing the transition!');
            });
    };

    // Funcție nouă pentru gestionarea clicului pe butonul "AUTENTIFICARE"
    const handleAutentificare = async () => {
        const transition = transitions.find(t => t.name === 'AUTENTIFICARE');
        if (!transition) {
            setError('No transition found for status "AUTENTIFICARE"');
            return;
        }
        const transitionId = transition.id;

        try {
            // Apelăm endpointul pentru a trimite emailul de confirmare
            await axios.post('https://server-9p4hx.kinsta.app/api/tasks/send-authentication-email', {
                issueId,
                summary,
                contactName: name,
                contactEmail: email
            });

            // Apelăm endpointul pentru a trimite SMS-ul de confirmare
            await axios.post('https://server-9p4hx.kinsta.app/api/tasks/send-authentication-sms', {
                contactName: name,
                contactPhone: phone,
                summary
            });

            // Tranziționăm taskul la statusul "AUTENTIFICARE"
            await axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-transition`, {
                issueId,
                transitionId
            });

            setStatus("AUTENTIFICARE");
            showStatusUpdatedMessage();
        } catch (error) {
            console.error('There was an error performing the transition or sending the email/SMS!', error);
            setError('There was an error performing the transition or sending the email/SMS!');
        }
    };

    const handleRefuse = async () => {
        const transition = transitions.find(t => t.name === 'Refuzat');
        if (!transition) {
            setError('No transition found for status "Refuzat"');
            return;
        }
        const transitionId = transition.id;

        try {
            const customFieldOptionsResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-custom-field-options/customfield_10161`);
            const option = customFieldOptionsResponse.data.find(option => option.label === 'Da');

            if (!option) {
                setError('Option "Da" not found for custom field customfield_10161');
                return;
            }

            const optionId = option.value;

            await axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-transition`, {
                issueId,
                transitionId,
                customField: "customfield_10161",
                customFieldValue: optionId
            });

            setStatus("Refuzat"); // Setează noul status
            showStatusUpdatedMessage();
        } catch (error) {
            console.error('There was an error performing the transition!', error);
            setError('There was an error performing the transition!');
        }
    };

    const handleTotalRefuse = async () => {
        const transition = transitions.find(t => t.name === 'Refuzat');
        if (!transition) {
            setError('No transition found for status "Refuzat"');
            return;
        }
        const transitionId = transition.id;

        try {
            const customFieldOptionsResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-custom-field-options/customfield_10160`);
            const option = customFieldOptionsResponse.data.find(option => option.label === 'Da');

            if (!option) {
                setError('Option "Da" not found for custom field customfield_10160');
                return;
            }

            const optionId = option.value;

            await axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-transition`, {
                issueId,
                transitionId,
                customField: "customfield_10160",
                customFieldValue: optionId
            });

            setStatus("Refuzat"); // Setează noul status
            showStatusUpdatedMessage();
        } catch (error) {
            console.error('There was an error performing the transition!', error);
            setError('There was an error performing the transition!');
        }
    };

    const showStatusUpdatedMessage = () => {
        setIsStatusUpdated(true);
        setTimeout(() => {
            setIsStatusUpdated(false);
            onClose();
            reloadTasks();
        }, 2000);
    };

    const handleCustomFieldChange = (field, value) => {
        setCustomFieldValues(prevValues => ({
            ...prevValues,
            [field]: value
        }));
    };

    // Funcție pentru a gestiona clicul pe butonul de analiză
    const handleAnalysisClick = () => {
        setManualAvgPrice(customFieldValues.customfield_10073); // Setăm manualAvgPrice în TaskEdit
        setShowAnalysis(true);
    };

    // Funcție pentru a reveni din pagina de analiză
    const handleBackFromAnalysis = () => {
        setShowAnalysis(false);
    };

    // Funcție pentru a seta manualAvgPrice (va fi apelată când se schimbă Prețul de Vânzare)
    const handleManualAvgPriceChange = (price) => {
        setManualAvgPrice(price);
    };

    const LoadingAnimation = () => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: '#1E90FF', marginTop: '20px' }}>
            <CircularProgress size={60} sx={{ color: '#6a0dad' }} />
            <Typography variant="h6" sx={{ marginTop: '20px', color: '#6a0dad' }}>Loading history...</Typography>
        </Box>
    );

    const renderHistory = () => {
        const groupedHistory = history.reduce((acc, item) => {
            if (!acc[item.status]) acc[item.status] = [];
            acc[item.status].push(item);
            return acc;
        }, {});
        return (
            <Box sx={{ marginBottom: '20px' }}>
                <Accordion sx={{ boxShadow: 'none', border: 'none' }} expanded={expandedHistory} onChange={() => setExpandedHistory(!expandedHistory)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ backgroundColor: '#fff', borderRadius: '8px', padding: '0 16px' }}
                    >
                        <DocIcon style={{ marginRight: '16px' }} />
                        <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', color: '#6a0dad' }}>Istoric</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: '#fff', padding: '16px' }}>
                                        <Button
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    onClick={handleRefreshHistory}
                    sx={{
                        marginTop: '10px',
                        backgroundColor: '#FFD700',
                        '&:hover': {
                            backgroundColor: '#FFC700',
                        },
                        color: '#FF8C00',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        height: '40px',
                        borderRadius: '8px',
                        width: '100%',
                    }}
                >
                    Refresh History
                </Button>
                
                        {isLoadingHistory ? (
                            <LoadingAnimation />
                        ) : history.length === 0 ? (
                            <Typography variant="body2" component="p">Nu există istoric disponibil.</Typography>
                        ) : (
                            Object.keys(groupedHistory).map(status => (
                                <Box key={status} sx={{ marginBottom: '20px' }}>
                                    <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', color: '#6a0dad', marginBottom: '10px' }}>{status}</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', padding: '10px', borderRadius: '8px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '8px 0', borderBottom: '1px solid #ccc' }}>
                                            <Typography variant="body1" component="p" sx={{ flex: 1, fontWeight: 'bold' }}>Denumire</Typography>
                                            <Typography variant="body1" component="p" sx={{ flex: 1, fontWeight: 'bold', padding: '0px 0px 0px 30px' }}>Contract</Typography>
                                            <Typography variant="body1" component="p" sx={{ flex: 1, fontWeight: 'bold' }}>Data</Typography>
                                        </Box>
                                        {groupedHistory[status].map((item, index) => (
                                            <Box key={index} sx={{ display: 'flex', flexDirection: 'row', padding: '10px 0', borderBottom: '1px solid #eee' }}>
                                                <Typography variant="body2" component="p" sx={{ flex: 1 }}>{item.summary}</Typography>
                                                <Typography variant="body2" component="p" sx={{ flex: 1, padding: '0px 0px 0px 10px' }}>
                                                    {item.tip_contract !== 'N/A' ? item.tip_contract : item.tip_achizitie}
                                                </Typography>
                                                <Typography variant="body2" component="p" sx={{ flex: 1 }}>{new Date(item.data_de_intrare).toLocaleDateString()}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ))
                        )}
                    </AccordionDetails>
                </Accordion>

            </Box>
        );
    };

    const handleContractInputChange = (e) => {
        const { name, value } = e.target;
        setContractData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCreareActAditional = async () => {
        const validation = validateFields();
        setWarnings(validation.warnings);

        if (!validation.isValid) {
            return; // Nu trimite cererea dacă validarea eșuează
        }

        if (!vendorData) {
            alert('Datele vânzătorului nu sunt disponibile!');
            return;
        }

        if (!tipContract || tipContract === 'Unknown') {
            await fetchTipContract();

            if (!tipContract || tipContract === 'Unknown') {
                alert('Tipul contractului nu a fost determinat corect. Verificați datele din task.');
                return;
            }
        }

        try {
            const addressCI = vendorData.addresses?.find((address) => address.categorie === 'ci') || {};
            const consolidatedAddress = `${addressCI.address_line1 || ''}, ${addressCI.city || ''}, ${addressCI.state || ''}, ${addressCI.country || ''}`;

            const requestData = {
                serieCI: vendorData.vendor?.serieCI || 'N/A',
                nrCI: vendorData.vendor?.nrCI || 'N/A',
                adresaLinia1: consolidatedAddress || 'Adresă indisponibilă',
                email: vendorData.vendor?.email || '',
                telefon: vendorData.vendor?.phone || '',
                Tip_Contract: tipContract,
                nume: vendorData.vendor?.name || '',
                pretFinal: customFieldValues.customfield_10181 || '0',
                NrContract: customFieldValues.customfield_10066 || 'N/A',
                dataIntrare: customFieldValues.customfield_10077 || 'N/A',
            };

            const response = await axios.post('https://server-9p4hx.kinsta.app/api/contracts/signnow/workflow-act-aditional-diminuare', requestData);

            if (response.data.success) {
                setAlertMessage(response.data.message || 'Actul adițional a fost creat și trimis cu succes!');
                setAlertType('success'); // Tipul succes
                setShowAlert(true); // Afișează alerta
            } else {
                setAlertMessage(response.data.message || 'A apărut o eroare la crearea actului adițional.');
                setAlertType('error'); // Tipul eroare
                setShowAlert(true); // Afișează alerta
            }
        } catch (error) {
            setAlertMessage(error.response?.data?.message || 'A apărut o eroare la crearea actului adițional.');
            setAlertType('error'); // Tipul eroare
            setShowAlert(true); // Afișează alerta
        }
    };

    useEffect(() => {
        if (!email || email.trim() === '') {
            console.warn('Skipping API call due to empty email');
            return;
        }

        const fetchRequests = async () => {
            try {
                const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-by-email?email=${email}`);
                console.log('Fetched requests:', response.data);
            } catch (error) {
                console.error('Error fetching requests:', error);
            }
        };

        fetchRequests();
    }, [email]);

    const fetchVendorData = async () => {
        if (!email) {
            console.warn('Email is undefined, skipping fetchVendorData');
            return;
        }

        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/vendor/${email}`);
            setVendorData(response.data);
            console.log('Fetched vendor data:', response.data);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.warn(`Vendor not found for email: ${email}`);
                setVendorData(null); // Inițializează cu null sau date implicite
            } else {
                console.error('Error fetching vendor data:', error);
            }
        }
    };

    useEffect(() => {
    const fetchCustomFieldOptions = async () => {
        const fieldId = 'customfield_10183';
        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-custom-field-options/${fieldId}`);
            const options = response.data;
            console.log('Fetched custom field options:', options); // Verifică răspunsul complet
            setCustomFieldOptions((prev) => ({
                ...prev,
                [fieldId]: options,
            }));
        } catch (error) {
            console.error('Error fetching custom field options:', error);
        }
    };



        fetchCustomFieldOptions();
    }, []);

    const fetchTipContract = async () => {
        const fieldId = 'customfield_10183';
        try {
            // Fetch task details
            const taskResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task-qc/${issueId}/fields`);
            const taskData = taskResponse.data;

            console.log('JIRA task response:', taskData); // Loghează răspunsul complet pentru debugging

            // Verificăm dacă există deja câmpul `Contract` și îl folosim
            if (taskData.Contract) {
                setTipContract(taskData.Contract);
                console.log('Tip Contract setat direct din Contract:', taskData.Contract);
                return;
            }

            // Continuăm logica normală dacă `Contract` nu este disponibil
            const jiraValue = taskData?.customfield_10183;
            if (!jiraValue) {
                console.warn(`JIRA field ${fieldId} is missing or undefined:`, taskData);
                setTipContract('Unknown');
                return;
            }

            console.log(`JIRA value for ${fieldId}:`, jiraValue);

            // Fetch custom field options
            const optionsResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-custom-field-options/${fieldId}`);
            const fieldOptions = optionsResponse.data;

            console.log(`Fetched options for ${fieldId}:`, fieldOptions);

            // Map the value to the corresponding label
            const matchedOption = fieldOptions.find((opt) => opt.value === jiraValue);

            if (!matchedOption) {
                console.warn(`No matching option found for ${jiraValue} in field ${fieldId}`);
                setTipContract('Unknown');
            } else {
                setTipContract(matchedOption.label);
                console.log('Mapped Tip_Contract:', matchedOption.label);
            }
        } catch (error) {
            console.error('Error fetching Tip_Contract:', error);
            setTipContract('Unknown');
        }
    };

    const validateFields = () => {
        const addressCI = vendorData?.addresses?.find((address) => address.categorie === 'ci') || {};
        const isAddressComplete = addressCI.address_line1 && addressCI.city && addressCI.state && addressCI.postal_code && addressCI.country;
        const isVendorDataComplete = vendorData?.vendor?.serieCI && vendorData?.vendor?.nrCI;
        const isJiraDataComplete = tipContract && customFieldValues.customfield_10066 && customFieldValues.customfield_10181;

        const warnings = {
            address: !isAddressComplete ? 'Te rog completează corect adresa și datele din buletin pentru a crea actul adițional.' : null,
            jiraData: !isJiraDataComplete ? 'Te rog completează corect datele în JIRA în proiectul LEB.' : null,
        };

        return {
            isValid: isAddressComplete && isVendorDataComplete && isJiraDataComplete,
            warnings,
        };
    };

    const validation = validateFields();
    const isButtonDisabled = !validation.isValid;


    const retryFetchTipContract = async (retries = 3) => {
        for (let i = 0; i < retries; i++) {
            await fetchTipContract();
            if (tipContract && tipContract !== 'Unknown') {
                console.log('Tip Contract set successfully:', tipContract);
                break; // Exit the loop if tipContract is valid
            }
            console.warn(`Retrying fetchTipContract... Attempt ${i + 1}`);
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before retrying
        }
    };

    const handleOnlineAction = async (action) => {
        try {
            const payload = {
                status: '44_PRELUNGIT', // Target status
                //customFields: {
                //    customfield_10085: action, // Example: 'Automat' or 'ACT'
                //},
            };

            console.log('Request URL:', `https://server-9p4hx.kinsta.app/api/tasks/set-status/${summary}`);
            console.log('Request Payload:', payload);

            axios.post(`https://server-9p4hx.kinsta.app/api/tasks/set-status/${summary}`, payload, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    console.log('Response:', response.data);
                })
                .catch((error) => {
                    console.error('Error:', error.response ? error.response.data : error.message);
                });

            console.log(`Sending payload:`, payload);

            const response = await axios.post(
                `https://server-9p4hx.kinsta.app/api/tasks/set-status/${summary}`,
                payload
            );

            if (response.status === 200) {
                console.log(`Task updated successfully with status "44_PRELUNGIT" and customFields:`, payload.customFields);
                alert(`Task updated successfully with status "44_PRELUNGIT"`);
            } else {
                console.error('An error occurred while updating the task:', response.data);
                alert('An error occurred while updating the task.');
            }
        } catch (error) {
            console.error('Error updating task in CONT:', error.response?.data || error.message);
            alert('An error occurred while updating the task.');
        }
    };


    const handleRestituitAction = () => {
        const transition = transitions.find(t => t.name === 'RESTITUIT'); // Căutăm tranziția "RESTITUIT"
        if (!transition) {
            setError('No transition found for status "RESTITUIT"');
            return;
        }

        const transitionId = transition.id;

        axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-transition`, {
            issueId,
            transitionId,
        })
            .then(() => {
                setStatus("RESTITUIT"); // Setăm noul status la "RESTITUIT"
                showStatusUpdatedMessage();
            })
            .catch(error => {
                console.error('There was an error performing the transition to "RESTITUIT"!', error);
                setError('There was an error performing the transition to "RESTITUIT"!');
            });
    };

    const extractNrJurnal = (description) => {
        const match = description.match(/Unique\s*I\s*D\s*[\t ]*([\d]+)/i);
        return match ? match[1] : null; // Returnăm valoarea dacă este găsită, altfel null
    };

    useEffect(() => {
        retryFetchTipContract();
    }, [issueId]);


    useEffect(() => {
        if (issueId) {
            fetchTipContract();
        }
    }, [issueId]);


    useEffect(() => {
        const validateTipContract = async () => {
            if (tipContract === 'Unknown') {
                console.warn('TipContract este necunoscut. Încercăm să revalidăm...');
                await fetchTipContract();
            }
        };

        validateTipContract();
    }, [tipContract]);


    useEffect(() => {
    if (email) {
                console.log('Email updated, fetching vendor data...');
                fetchVendorData();
            } else {
                console.warn('Email is undefined, skipping fetchVendorData');
            }
        }, [email]);

        useEffect(() => {
        console.log('Warnings:', warnings);
    }, [warnings]);

    return (
         <>
            {showAnalysis ? (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#fff',
                        zIndex: 1300,
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto', // Permite scroll-ul pe axa verticală
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleBackFromAnalysis}
                        sx={{ margin: '20px', width: '100px' }}
                    >
                        Back
                    </Button>
                    <Page18 manualAvgPrice={manualAvgPrice} /> {/* Transmiterea prețului către Page18 */}
                </Box>
            ) : (
                <Box sx={{ marginLeft: '20px', flex: 1, ...style }}>
                    {isStatusUpdated && (
                        <Card
                            sx={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 9999,
                                minWidth: 300,
                                backgroundColor: '#f0f4c3',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px',
                            }}
                        >
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <CheckCircleOutlineIcon sx={{ fontSize: 48, color: '#4caf50', marginBottom: '16px' }} />
                                <Typography variant="h6" sx={{ color: '#4caf50', fontWeight: 'bold' }}>Status Actualizat</Typography>
                            </CardContent>
                        </Card>
                    )}


                    {firstImageAttachment && (
                        <img
                            src={`https://server-9p4hx.kinsta.app/api/tasks/attachments/${firstImageAttachment.id}`}
                            alt={firstImageAttachment.filename}
                            style={{ width: '95%', borderRadius: '8px', marginBottom: '20px' }}
                            onDrop={handleDrop}
                            onDragOver={(e) => e.preventDefault()}
                        />
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        {attachments.map(att => (
                            <Box key={att.id}>
                                {renderAttachmentPreview(att)}
                            </Box>
                        ))}
                    </Box>
                    {isEditingSummary ? (
                        <input
                            type="text"
                            value={newSummary}
                            onChange={handleSummaryChange}
                            onBlur={handleSummaryBlur}
                            autoFocus
                            style={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                width: '100%',
                                border: 'none',
                                outline: 'none',
                                marginBottom: '20px'
                            }}
                        />
                    ) : (
                        <Typography
                            variant="h5"
                            component="h2"
                            onClick={handleSummaryClick}
                            sx={{ cursor: 'pointer', fontWeight: 'bold', marginBottom: '20px' }}
                        >
                            {summary}
                        </Typography>
                    )}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '20px',
                            gap: '16px', // Spațiu fix între butoane
                        }}
                    >
                        {(tab === 'created' || tab === 'prospect') && (
                            <Button
                                variant="contained"
                                onClick={handleAccept}
                                sx={{
                                    flex: 1, // Fiecare buton ocupă spațiu egal
                                    backgroundColor: tab === 'created' ? '#1E90FF' : '#32CD32',
                                    '&:hover': {
                                        backgroundColor: tab === 'created' ? '#1C86EE' : '#228B22',
                                    },
                                    color: '#FFFFFF',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    height: '40px',
                                    borderRadius: '8px',
                                }}
                            >
                                {tab === 'created' ? 'Prospect' : 'Acceptat'}
                            </Button>
                        )}

                        {tab === 'acceptat' && (
                            <Button
                                variant="contained"
                                onClick={handleTransit}
                                sx={{
                                    flex: 1, // Fiecare buton ocupă spațiu egal
                                    backgroundColor: '#1E90FF',
                                    '&:hover': {
                                        backgroundColor: '#1C86EE',
                                    },
                                    color: '#FFFFFF',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    height: '40px',
                                    borderRadius: '8px',
                                }}
                            >
                                Tranzit
                            </Button>
                        )}

                        {tab === 'tranzit' && (
                            <Button
                                variant="contained"
                                onClick={handleAutentificare}
                                sx={{
                                    flex: 1,
                                    backgroundColor: '#FF4500',
                                    '&:hover': {
                                        backgroundColor: '#FF6347',
                                    },
                                    color: '#FFFFFF',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    height: '40px',
                                    borderRadius: '8px',
                                }}
                            >
                                AUTENTIFICARE
                            </Button>
                        )}

                        {tab === 'autentificare' && (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', width: '100%' }}>
                                <Button
                                    variant="contained"
                                    onClick={handleOnline}
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#1E90FF',
                                        '&:hover': {
                                            backgroundColor: '#1C86EE',
                                        },
                                        color: '#FFFFFF',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        height: '40px',
                                        borderRadius: '8px',
                                    }}
                                >
                                    ONLINE
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleRespins}
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#FF4500',
                                        '&:hover': {
                                            backgroundColor: '#FF6347',
                                        },
                                        color: '#FFFFFF',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        height: '40px',
                                        borderRadius: '8px',
                                    }}
                                >
                                    RESPINS
                                </Button>
                            </Box>
                        )}

                        {tab !== 'autentificare' && tab !== 'online' && tab !== 'prospect' && tab !== 'tranzit' && tab !== 'created' && tab !== 'acceptat' && (
                            <Button
                                variant="contained"
                                onClick={handleRefuse}
                                sx={{
                                    flex: 1,
                                    backgroundColor: '#FFD700',
                                    '&:hover': {
                                        backgroundColor: '#FFC700',
                                    },
                                    color: '#FF8C00',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    height: '40px',
                                    borderRadius: '8px',
                                }}
                            >
                                Refuzat
                            </Button>
                        )}

                        {tab !== 'autentificare' && tab !== 'online' && (
                            <Button
                                variant="contained"
                                onClick={handleOpenPopup}
                                sx={{
                                    flex: 1,
                                    backgroundColor: '#FF4500',
                                    '&:hover': { backgroundColor: '#FF6347' },
                                    color: '#FFFFFF',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    height: '40px',
                                    borderRadius: '8px',
                                }}
                            >
                                Refuz Total
                            </Button>
                        )}

                        {tab === 'online' && (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', width: '100%' }}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleOnlineAction('Automat')}
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#4CAF50',
                                        '&:hover': { backgroundColor: '#45A049' },
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                    }}
                                >
                                    Automat
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handleOnlineAction('Act')}
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#2196F3',
                                        '&:hover': { backgroundColor: '#1976D2' },
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                    }}
                                >
                                    Prelungit Act
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleRestituitAction}
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#FF5722',
                                        '&:hover': { backgroundColor: '#E64A19' },
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                    }}
                                >
                                    Restituit
                                </Button>
                            </Box>
                        )}

                        <Modal open={showPopup} onClose={handleClosePopup}>
                            <Card
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '400px',
                                    padding: '20px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '8px',
                                    backgroundColor: '#fff',
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                                        Selectați motivul refuzului
                                    </Typography>
                                    <Select
                                        value={selectedReason}
                                        onChange={(e) => handleReasonChange(e.target.value)}
                                        fullWidth
                                        displayEmpty
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <MenuItem value="" disabled>
                                            Selectați un motiv
                                        </MenuItem>
                                        {salReasons.map((reason, index) => (
                                            <MenuItem key={index} value={reason}>
                                                {reason}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                                        Detalii suplimentare:
                                    </Typography>
                                    <TextField
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                        multiline
                                        rows={4}
                                        fullWidth
                                        placeholder="Introduceți detalii suplimentare..."
                                        variant="outlined"
                                        sx={{ marginBottom: '20px' }}
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleSaveRefuse}
                                            sx={{
                                                backgroundColor: '#FF4500',
                                                '&:hover': { backgroundColor: '#FF6347' },
                                                color: '#FFFFFF',
                                            }}
                                        >
                                            Refuz
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={handleClosePopup}
                                            sx={{
                                                borderColor: '#CCCCCC',
                                                color: '#000',
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Modal>
                    </Box>


                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Dețin:</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {tags.map((tag, index) => (
                                    <Chip
                                        key={index}
                                        label={tag}
                                        onDelete={handleDeleteTag(tag)}
                                        sx={{ margin: '5px' }}
                                    />
                                ))}
                            </Box>
                        </Box>
<Box sx={{ flex: 1 }}>
    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '95px' }}>Condiție:</Typography>
    <Typography variant="body1" component="p" sx={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '90px' }}>
        {customFieldValues.condition || 'N/A'}
    </Typography>
</Box>

                        <Box sx={{ flex: 1 }}>
                            <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '95px' }}>Preț:</Typography>
                            <Typography variant="body1" component="p" sx={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '90px' }}>{price} €</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1' }}>
                            <PersonIcon sx={{ color: '#6a0dad' }} />
                            <TextField
                                value={name}
                                onChange={(e) => setName(e.target.value.replace(/\s+/g, ' '))}
                                onBlur={handleSaveName}
                                placeholder="Nume"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    sx: { fontSize: '16px', textAlign: 'center' }
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1', marginLeft: '20px' }}>
                            <PhoneIcon sx={{ color: '#6a0dad' }} />
                            <TextField
                                value={phone}
                                onChange={(e) => setPhone(e.target.value.replace(/\s+/g, ' '))}
                                onBlur={handleSavePhone}
                                placeholder="Telefon"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    sx: { fontSize: '16px', textAlign: 'center' }
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1' }}>
                            <EmailIcon sx={{ color: '#6a0dad' }} />
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={handleSaveEmail}
                                placeholder="Email"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    sx: { fontSize: '16px', textAlign: 'center' }
                                }}
                            />
                        </Box>
                    </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {/* MDetalii vanzator */}
                    <VendorInfo email={email} onAddressSelect={handleAddressSelect} />
                    {/* Mutarea componentei ActiveRequests aici pentru a fi vizibilă în toate taburile */}
                    <ActiveRequests email={email} />
                </Box>


                    {/* Afișăm accordeonul "Istoric" și "Cautare produs AI" doar pentru taburile "created" și "prospect" */}
                    {(tab === 'created' || tab === 'prospect') ? (
                        <>
                            {renderHistory()}
                            <Accordion sx={{ boxShadow: 'none', border: 'none' }} expanded={expandedSearch}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    onClick={() => setExpandedSearch(!expandedSearch)}
                                    sx={{ backgroundColor: '#fff', borderRadius: '8px', padding: '0 16px' }}
                                >
                                    <ImgIcon style={{ marginRight: '16px', width: '48px', height: '48px' }} />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <Typography variant="h6" component="h3" sx={{ fontSize: '18px', marginRight: '8px', marginTop: '8px', lineHeight: '1', textAlign: 'left' }}>Cautare produs AI</Typography>
                                        {imageSearchResults.length > 0 && (
                                            <Typography variant="body2" component="p" sx={{ color: 'grey', textAlign: 'left' }}>
                                                {imageSearchResults.length} results
                                            </Typography>
                                        )}
                        </Box>
                    </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: '#fff', border: '1px solid #fff', borderRadius: '8px', padding: '16px' }}>
                                {isSearching ? (
                                    <LoadingAnimation />
                                ) : (
                                    <List>
                                        {imageSearchResults.map((result, index) => (
                                            <ListItem key={index} sx={{ backgroundColor: '#fff', border: '1px solid #fff', borderRadius: '8px', marginBottom: '10px' }}>
                                                <ListItemText
                                                    primary={<Link href={result.link} target="_blank">{result.title}</Link>}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </>
                    ) : tab === 'acceptat' ? (
                    
                <>

                        {/* Acte Confidentiale Accordion */}
                        <ActeConfidentiale email={email} setIban={setIban} setBankName={setBankName} />

                        {/* Creare Contract Accordion */}
                        <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#fff', padding: '0 16px' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#6a0dad' }}>Creare Contract</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '16px' }}>
                            <CreareContractForm
                                contractData={{
                                    ...contractData,
                                    contCurent: iban,
                                    banca: bankName,
                                    pretFinalVanzare: customFieldValues.customfield_10195 || '',
                                    dataPlataFinalVanzare: customFieldValues.customfield_10194 || '',
                                }}
                                handleInputChange={handleContractInputChange}
                            />
                            </AccordionDetails>
                        </Accordion>

                        {/* Creare AWB Accordion */}
                        <AwbAccordion issueId={issueId} contactName={name} contactPhone={phone} contactEmail={email} selectedAddress={selectedAddress} />
                    </>
                    ) : null}
                    
                    {tab === 'online' && (
                        vendorData ? (
                            <Box sx={{ marginTop: 3 }}>
                                {/* Preț Act și Expiră pe același rând */}
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '20px' }}>
                                    {/* Input-box pentru Preț Act */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography><strong>Preț Act:</strong></Typography>
                                        <TextField
                                            value={customFieldValues.customfield_10181}
                                            onChange={(e) => handleCustomFieldChange('customfield_10181', e.target.value)}
                                            placeholder="Introduceți prețul actului"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: <Typography sx={{ marginLeft: '8px', fontWeight: 'bold' }}>RON</Typography>,
                                            }}
                                            fullWidth
                                        />
                                    </Box>

                                    {/* Input-box pentru Expiră */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography><strong>Expiră:</strong></Typography>
                                        <TextField
                                            type="date"
                                            value={customFieldValues.customfield_10078}
                                            onChange={(e) => handleCustomFieldChange('customfield_10078', e.target.value)}
                                            placeholder="Introduceți data expirării"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Box>
                                    
                                </Box>
                                <Box sx={{ flex: 1, marginBottom: '20px' }}>
                                    <Typography><strong>Tip Contract:</strong></Typography>
                                    <Select
                                        value={customFieldValues.customfield_10183}
                                        onChange={(e) => handleCustomFieldChange('customfield_10183', e.target.value)}
                                        variant="outlined"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ marginLeft: '40px', fontSize: '16px', width: '80%' }}
                                    >
                                        <MenuItem value="" disabled>Select Luxura Policy</MenuItem>
                                        {customFieldOptions.customfield_10183 && customFieldOptions.customfield_10183.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                {/* Serie și Nr CI */}
                                <Typography><strong>Serie CI:</strong> {vendorData.vendor.serieCI || 'N/A'}</Typography>
                                <Typography><strong>Nr CI:</strong> {vendorData.vendor.nrCI || 'N/A'}</Typography>

                                {/* Adresă cu categoria 'ci' */}
                                {vendorData.addresses && vendorData.addresses.some(addr => addr.categorie === 'ci') ? (
                                    vendorData.addresses
                                        .filter(address => address.categorie === 'ci')
                                        .map((address, index) => (
                                            <Box key={index} sx={{ marginBottom: 1 }}>
                                                <Typography><strong>Linia 1:</strong> {address.address_line1 || 'N/A'}</Typography>
                                                <Typography><strong>Linia 2:</strong> {address.address_line2 || 'N/A'}</Typography>
                                                <Typography><strong>Oraș:</strong> {address.city || 'N/A'}</Typography>
                                                <Typography><strong>Județ:</strong> {address.state || 'N/A'}</Typography>
                                                <Typography><strong>Cod Poștal:</strong> {address.postal_code || 'N/A'}</Typography>
                                                <Typography><strong>Țară:</strong> {address.country || 'N/A'}</Typography>
                                            </Box>
                                        ))
                                ) : (
                                    <Typography variant="body1">Nu există adresă CI.</Typography>
                                )}

                                {/* Tip Contract și Nr Contract */}
                                <Typography sx={{ marginTop: 2 }}>
                                    <strong>Tip Contract:</strong> {tipContract || 'N/A'}
                                </Typography>
                                <Typography>
                                    <strong>Nr Contract:</strong> {customFieldValues.customfield_10066 || 'N/A'}
                                </Typography>
                                <Typography>
                                    <strong>Preț Final:</strong> {customFieldValues.customfield_10181 || 'N/A'}
                                </Typography>

                                {/* Buton pentru Creare Act Adițional */}
                                

                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                    <Box>
                                        {(warnings.address || warnings.jiraData) && (
                                            <Box sx={{ border: '1px solid blue', marginBottom: '20px' }}>
                                                {warnings.address && (
                                                    <Alert severity="error" sx={{ marginBottom: '10px', borderRadius: '8px' }}>
                                                        {warnings.address}
                                                    </Alert>
                                                )}
                                                {warnings.jiraData && (
                                                    <Alert severity="error" sx={{ marginBottom: '10px', borderRadius: '8px' }}>
                                                        {warnings.jiraData}
                                                    </Alert>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            
                            ) : (
                                <Box sx={{ marginTop: 3 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Luxura Policy:</Typography>
                                    <Select
                                        value={customFieldValues.customfield_10183}
                                        onChange={(e) => handleCustomFieldChange('customfield_10183', e.target.value)}
                                        variant="outlined"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ marginLeft: '40px', fontSize: '16px', width: '80%' }}
                                    >
                                        <MenuItem value="" disabled>Select Luxura Policy</MenuItem>
                                        {customFieldOptions.customfield_10183 && customFieldOptions.customfield_10183.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            )
                    )}

                    {showAlert && (
                        <Box sx={{ marginBottom: 2 }}>
                            <Alert
                                severity={alertType} // 'success' sau 'error'
                                onClose={() => setShowAlert(false)} // Închide alerta
                                sx={{
                                    borderRadius: '8px',
                                    backgroundColor: alertType === 'success' ? '#E8F5E9' : '#FFEBEE',
                                    color: alertType === 'success' ? '#388E3C' : '#D32F2F',
                                    fontWeight: 'bold',
                                }}
                                icon={alertType === 'success' ? <CheckCircleOutlineIcon fontSize="inherit" /> : <ErrorOutlineIcon fontSize="inherit" />}
                            >
                                {alertMessage}
                            </Alert>
                        </Box>
                    )}

                    {tab !== 'autentificare' && tab !== 'online' && (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '20px' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Tip Achizitie:</Typography>
                                    <Select
                                        value={customFieldValues.customfield_10080}
                                        onChange={(e) => handleCustomFieldChange('customfield_10080', e.target.value)}
                                        variant="standard"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ marginLeft: '40px', fontSize: '16px' }}
                                    >
                                        <MenuItem value="" disabled>Select Tip Achizitie</MenuItem>
                                        {customFieldOptions.customfield_10080 && customFieldOptions.customfield_10080.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Luxura Policy:</Typography>
                                    <Select
                                        value={customFieldValues.customfield_10183}
                                        onChange={(e) => handleCustomFieldChange('customfield_10183', e.target.value)}
                                        variant="standard"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ marginLeft: '40px', fontSize: '16px' }}
                                    >
                                        <MenuItem value="" disabled>Select Luxura Policy</MenuItem>
                                        {customFieldOptions.customfield_10183 && customFieldOptions.customfield_10183.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Data de Intrare:</Typography>
                                    <TextField
                                        type="date"
                                        value={customFieldValues.customfield_10077}
                                        onChange={(e) => handleCustomFieldChange('customfield_10077', e.target.value)}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            sx: { fontSize: '16px', textAlign: 'center' }
                                        }}
                                        sx={{ marginLeft: '40px' }}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Expira Contract:</Typography>
                                    <TextField
                                        type="date"
                                        value={customFieldValues.customfield_10078}
                                        onChange={(e) => handleCustomFieldChange('customfield_10078', e.target.value)}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            sx: { fontSize: '16px', textAlign: 'center' }
                                        }}
                                        sx={{ marginLeft: '40px' }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Nr Jurnal:</Typography>
                                    <TextField
                                        value={customFieldValues.customfield_10066}
                                        onChange={(e) => handleCustomFieldChange('customfield_10066', e.target.value)}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            sx: { fontSize: '16px', textAlign: 'center' }
                                        }}
                                        sx={{ marginLeft: '40px' }}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Categorie:</Typography>
                                    <Select
                                        value={customFieldValues.customfield_10173}
                                        onChange={(e) => handleCustomFieldChange('customfield_10173', e.target.value)}
                                        variant="standard"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ marginLeft: '40px', fontSize: '16px' }}
                                    >
                                        <MenuItem value="" disabled>Select Categorie</MenuItem>
                                        {customFieldOptions.customfield_10173 && customFieldOptions.customfield_10173.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Preț de Intrare (RON):</Typography>
                                    <TextField
                                        type="number"
                                        value={customFieldValues.customfield_10072}
                                        onChange={(e) => handleCustomFieldChange('customfield_10072', parseFloat(e.target.value))}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            sx: { fontSize: '16px', textAlign: 'center' }
                                        }}
                                        sx={{ marginLeft: '40px' }}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Preț de Vânzare (RON):</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
                                        <TextField
                                            type="number"
                                            value={customFieldValues.customfield_10073}
                                            onChange={(e) => handleCustomFieldChange('customfield_10073', parseFloat(e.target.value))}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                sx: { fontSize: '16px', textAlign: 'center' }
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            onClick={handleAnalysisClick} // Acționează la clic pe Analiza
                                            sx={{
                                                minWidth: '40px',
                                                minHeight: '40px',
                                                padding: '8px',
                                                backgroundColor: customFieldValues.customfield_10073 ? '#1E90FF' : '#cccccc',
                                                '&:hover': {
                                                    backgroundColor: customFieldValues.customfield_10073 ? '#1C86EE' : '#cccccc',
                                                },
                                                color: '#FFFFFF',
                                                borderRadius: '50%', // Face butonul rotund
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            disabled={!customFieldValues.customfield_10073}
                                        >
                                            <AnalysisIcon />
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Achizitor:</Typography>
                                    <Select
                                        value={customFieldValues.customfield_10165}
                                        onChange={(e) => handleCustomFieldChange('customfield_10165', e.target.value)}
                                        variant="standard"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ marginLeft: '40px', fontSize: '16px' }}
                                    >
                                        <MenuItem value="" disabled>Select Achizitor</MenuItem>
                                        {customFieldOptions.customfield_10165 && customFieldOptions.customfield_10165.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Contactat:</Typography>
                                    <Select
                                        value={customFieldValues.customfield_10185}
                                        onChange={(e) => handleCustomFieldChange('customfield_10185', e.target.value)}
                                        variant="standard"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ marginLeft: '40px', fontSize: '16px' }}
                                    >
                                        <MenuItem value="" disabled>Select Contactat</MenuItem>
                                        {customFieldOptions.customfield_10185 && customFieldOptions.customfield_10185.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                                <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '40px' }}>Nota interna:</Typography>
                                <TextField
                                    value={customFieldValues.customfield_10186}
                                    onChange={(e) => handleCustomFieldChange('customfield_10186', e.target.value)}
                                    placeholder="Scrie aici nota interna"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginLeft: '40px', marginRight: '40px', fontSize: '16px' }}
                                />
                            </Box>
                        </>
                    )}
                    {tab === 'autentificare' && (
                    <AutentificareStep
                        key={issueId} // adaugă acest key pentru a forța re-render
                        issueId={issueId}
                        customFieldValue={customFieldValues.customfield_10188}
                        onUpdateCustomField={(newValue) => handleCustomFieldChange('customfield_10188', newValue)}
                        contactName={name}    // Transmitem numele
                        contactPhone={phone}  // Transmitem telefonul
                        contactEmail={email}  // Transmitem emailul
                        category={customFieldValues.customfield_10173}  // Adăugăm categoria
                        summary={summary}
                    />

                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                            sx={{
                                width: tab === 'online' ? '48%' : '100%', // Ajustează dimensiunea doar pentru online
                                backgroundColor: '#87CEEB', // Albastru ca ceru
                                '&:hover': {
                                    backgroundColor: '#00BFFF', // Albastru mai închis la hover
                                },
                                color: '#1E90FF', // Albastru mai închis pentru text și icon
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                height: '48px',
                                borderRadius: '8px',
                            }}
                        >
                            SAVE
                        </Button>
                        {(tab === 'created' || tab === 'prospect') && (
                            <Button
                                variant="contained"
                                startIcon={<SearchIcon />}
                                onClick={handleImageSearch}
                                sx={{
                                    width: '48%', // Asigurăm aceeași lățime ca la butonul SAVE
                                    backgroundColor: '#FFD700', // Galben
                                    '&:hover': {
                                        backgroundColor: '#FFC700', // Galben mai închis la hover
                                    },
                                    color: '#FF8C00', // Portocaliu închis pentru text și icon
                                    justifyContent: 'center',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    height: '48px', // Setăm aceeași înălțime ca la butonul SAVE
                                    padding: '8px 16px',
                                    borderRadius: '8px',
                                    marginLeft: '10px',
                                }}
                            >
                                SEARCH IMAGE
                            </Button>
                        )}
                        {tab === 'online' && (
                            <Button
                                variant="contained"
                                startIcon={<CheckCircleIcon />} // Icon pentru Creare Act Adițional
                                onClick={handleCreareActAditional}
                                disabled={isButtonDisabled}
                                sx={{
                                    width: '48%',
                                    backgroundColor: isButtonDisabled ? '#ccc' : '#007BFF',
                                    color: isButtonDisabled ? '#888' : 'white',
                                    fontWeight: 'bold',
                                    padding: '8px 16px',
                                    fontSize: '16px',
                                    borderRadius: '8px',
                                    boxShadow: isButtonDisabled ? 'none' : '0 4px 12px rgba(0, 123, 255, 0.3)',
                                    '&:hover': {
                                        backgroundColor: isButtonDisabled ? '#ccc' : '#0056b3',
                                    },
                                }}
                            >
                                Act Adițional
                            </Button>
                        )}
                    </Box>

                </Box>
            )}
        </>
    );
};
export default TaskEdit;
