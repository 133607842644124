// AlexandraPayout.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { PieChart, Pie, Cell } from 'recharts';

const AlexandraPayout = ({ data }) => {
  // Filtrăm datele pentru intrările unde achizitorul este  "Alexandra Neculcea"
  const filteredData = data.filter(
    item => item.achizitor === 'Alexandra Neculcea'
  );

  // Inițializăm variabilele
  let totalAmount = 0;
  let numProductsUnder2000 = 0;
  let numProductsOver2000 = 0;

  filteredData.forEach(item => {
    const pretIntrare = item.pretIntrareRON;

    if (pretIntrare <= 1999) {
      numProductsUnder2000 += 1;
    } else {
      numProductsOver2000 += 1;
    }
  });

  // Calculăm suma pentru produsele sub sau egal cu 2000 lei
  const amountUnder2000 = numProductsUnder2000 * 20;
  totalAmount += amountUnder2000;

  // Calculăm suma pentru produsele peste 2000 lei conform noii reguli
  let amountOver2000 = 0;
  if (numProductsOver2000 < 30) {
    amountOver2000 = numProductsOver2000 * 40 + 2000; // Aplica bonusul de 1500 doar sub 30
    totalAmount += amountOver2000;
  } else if (numProductsOver2000 >= 30 && numProductsOver2000 < 50) {
    const extraProducts = numProductsOver2000 - 30;
    amountOver2000 = 3000 + extraProducts * 40; // Aplica bonusul de 3000 după 30 produse
    totalAmount += amountOver2000;
  } else {
    const extraProducts = numProductsOver2000 - 50;
    amountOver2000 = 6000 + extraProducts * 40; // Aplica bonusul de 6000 după 50 produse
    totalAmount += amountOver2000;
  }

  // Pregătim datele pentru grafic (doar produsele peste 2000 lei)
  const maxProductsOver2000 = 50;
  const productsRemainingOver2000 =
    maxProductsOver2000 - numProductsOver2000 > 0
      ? maxProductsOver2000 - numProductsOver2000
      : 0;

  const pieData = [
    { name: 'Produse peste 2000 lei', value: numProductsOver2000 },
    { name: 'Produse rămase', value: productsRemainingOver2000 },
  ];

  const COLORS = ['#0088FE', '#FF0000']; // Albastru pentru produse adăugate, roșu pentru produse rămase

  // Creăm un sumar al calculelor
  let summaryLines = [];

  if (numProductsUnder2000 > 0) {
    summaryLines.push(
      `20 lei x ${numProductsUnder2000} produse sub 2000 lei = ${
        20 * numProductsUnder2000
      } lei`
    );
  }

  if (numProductsOver2000 > 0) {
    if (numProductsOver2000 < 30) {
      summaryLines.push(
        `40 lei x ${numProductsOver2000} produse peste 2000 lei = ${
          40 * numProductsOver2000
        } lei`
      );
      summaryLines.push(`Bonus pentru sub 30 produse: 2000 lei`);
    } else if (numProductsOver2000 >= 30 && numProductsOver2000 < 50) {
      const extraProducts = numProductsOver2000 - 30;
      const extraAmount = extraProducts * 40;
      summaryLines.push(`3000 lei pentru primele 30 produse peste 2000 lei`);
      if (extraProducts > 0) {
        summaryLines.push(
          `40 lei x ${extraProducts} produse peste 30 = ${extraAmount} lei`
        );
      }
    } else {
      const extraProducts = numProductsOver2000 - 50;
      const extraAmount = extraProducts * 40;
      summaryLines.push(`6000 lei pentru 50 sau mai multe produse peste 2000 lei`);
      if (extraProducts > 0) {
        summaryLines.push(
          `40 lei x ${extraProducts} produse peste 50 = ${extraAmount} lei`
        );
      }
    }
  }

  // Formatarea numerelor
  const formatNumber = number => {
    return number.toLocaleString('ro-RO', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Alexandra Payout</Typography>
        <Box
          sx={{
            position: 'relative',
            width: 400,
            height: 400,
            margin: '0 auto',
          }}
        >
          <PieChart width={400} height={400}>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              innerRadius={80}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={2}
              dataKey="value"
              startAngle={90}
              endAngle={-270}
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
          {/* Afișăm suma în centrul  graficului */}
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5">
              {formatNumber(totalAmount)} lei
            </Typography>
          </Box>
        </Box>
        {/* Afișăm sumarul  */}
        {summaryLines.map((line, index) => (
          <Typography key={index} variant="body2">
            {line}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};

export default AlexandraPayout;
